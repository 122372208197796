import { AbilityBuilder, Ability } from '@casl/ability';

// User roles
export const ADMIN_USER_ROLE = 'admin';
export const TICKETING_USER_ROLE = 'ticketing';
export const V_TICKETING_USER_ROLE = 'vticketing';
const CRM_USER_ROLE = 'crm';
const ENGINEER_USER_ROLE = 'engineer';
export const CUSTOMER_PORTAL = 'customer-portal';
const OPERATIONS_ROLE = 'operations';
export const PROVISIONING = 'provisioning';

// Subjects
export const DASHBOARD_ACCESS_SUBJECT = 'Dashboard';
export const PRODUCT_CONFIG_ACCESS_SUBJECT = 'Product Config';
export const TICKETING_ACCESS_SUBJECT = 'Tickets';
export const V_TICKETING_ACCESS_SUBJECT = 'VTickets';
export const CRM_ACCESS_SUBJECT = 'CRM';
export const ORDERS_ACCESS_SUBJECT = 'Orders';
export const ORDERS_ACCESS_SUBJECT_DETAILS = 'Orders Details';
export const PRODUCT_MANAGEMENT_ACCESS_SUBJECT = 'Product Management';
export const PRICING_MANAGEMENT_ACCESS_SUBJECT = 'Pricing Management';
export const BILLING_ACCESS_SUBJECT = 'Billing';
export const EMAIL_LOG_ACCESS_SUBJECT = 'Email Logs';
export const CUSTOMIZATION_ACCESS_SUBJECT = 'Customization Center';
export const EXTENSIBILITY_CENTER_ACCESS_SUBJECT = 'Extensibility Center';
export const AMS_ACCESS_SUBJECT = 'Access management service';
export const WORKFLOW_ACCESS_SUBJECT = 'Workflow';
export const ROLES_ACCESS_SUBJECT = 'Roles & Permissions';
export const EQUIPMENT_RECORDS_ACCESS_SUBJECT = 'Equipment Records';
export const PROJECTS_ACCESS_SUBJECT = 'Projects';
export const ADVANCED_SEARCH_ACCESS_SUBJECT = 'Advanced Search';
export const PRODUCTS_QUANTITY = 'Quantity on service contract';
export const PAYMENT_PORTAL = 'Payment Portal';
export const ENABLE_LOGIN_CONFIGURATION = 'ENABLE_LOGIN_CONFIGURATION';
export const CONTACT = 'CONTACT';
export const LEDGER_NOTES = 'LEDGER_NOTES';
export const CONTRACT_RENEW_ACTIONS = 'CONTRACT_RENEW_ACTIONS';
export const REPORTS = 'REPORTS';
export const INVOICE_TAX_LOGS = 'INVOICE_TAX_LOGS';
export const CRM_ENABLE_LOGIN_FOR_CONTACT = 'CRM_ENABLE_LOGIN_FOR_CONTACT';
export const CRM_REMOVE_CONTACT_FROM_ORGANIZATION = 'CRM_REMOVE_CONTACT_FROM_ORGANIZATION';
export const CRM_CHANGE_USER_ROLES = 'CRM_CHANGE_USER_ROLES';
export const CRM_ORG_PAYMENT_TERMS_WEBSITE = 'CRM_ORG_PAYMENT_TERMS_WEBSITE';
export const CRM_ORG_CREDIT_CHECK_PASSED = 'CRM_ORG_CREDIT_CHECK_PASSED';
export const CRM_ORG_PAYMENT_TERMS_FEDERAL_TAX_ID = 'CRM_ORG_PAYMENT_TERMS_FEDERAL_TAX_ID';
export const CRM_ORG_PAYMENT_TERMS_BILLING_PLAN = 'CRM_ORG_PAYMENT_TERMS_BILLING_PLAN';
export const CRM_ORG_PAYMENT_TERMS_PAYMENT_TERMS = 'CRM_ORG_PAYMENT_TERMS_PAYMENT_TERMS';
export const CRM_ORG_PAYMENT_TERMS_TAX_EXEMPT_TYPE = 'CRM_ORG_PAYMENT_TERMS_TAX_EXEMPT_TYPE';
export const CRM_ORG_PAYMENT_TERMS_TAX_EXEMPT_LEVEL = 'CRM_ORG_PAYMENT_TERMS_TAX_EXEMPT_LEVEL';
export const CRM_ORG_PAYMENT_TERMS_REQUESTED_W9 = 'CRM_ORG_PAYMENT_TERMS_REQUESTED_W9';
export const CRM_ORG_PAYMENT_TERMS_RECEIVED_W9 = 'CRM_ORG_PAYMENT_TERMS_RECEIVED_W9';
export const CRM_ORG_PAYMENT_TERMS_PARENT_ORGANIZATION = 'CRM_ORG_PAYMENT_TERMS_PARENT_ORGANIZATION';
export const CRM_ORG_PAYMENT_TERMS_DEFAULT_ROLE = 'CRM_ORG_PAYMENT_TERMS_DEFAULT_ROLE';
export const CRM_ORG_PAYMENT_TERMS_ORGANIZATION_IS = 'CRM_ORG_PAYMENT_TERMS_ORGANIZATION_IS';
export const CRM_ORG_DESCRIPTION = 'CRM_ORG_DESCRIPTION';
export const CRM_ORG_ATTACHMENTS_DELETE = 'CRM_ORG_ATTACHMENTS_DELETE';
export const CRM_ORG_RELATIONSHIPS_DELETE = 'CRM_ORG_RELATIONSHIPS_DELETE';
export const HIDDEN_TICKETS_FILTER = 'HIDDEN_TICKETS_FILTER';
export const LOGS_ACCESS_SUBJECT = 'LOGS_ACCESS_SUBJECT';
export const DELETE_SERVICE_CONTRACT = 'DELETE_SERVICE_CONTRACT';

export const defineAbilitiesFor = (roles) => {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (roles.includes(V_TICKETING_USER_ROLE)) {
    can('manage', V_TICKETING_ACCESS_SUBJECT);
    can('manage', DASHBOARD_ACCESS_SUBJECT);
    can('read', ORDERS_ACCESS_SUBJECT_DETAILS);
    cannot('read', PRODUCTS_QUANTITY);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    cannot('read', LOGS_ACCESS_SUBJECT);
    cannot('read', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('read', INVOICE_TAX_LOGS);
  }

  if (roles.includes(TICKETING_USER_ROLE)) {
    can('manage', TICKETING_ACCESS_SUBJECT);
    can('read', ORDERS_ACCESS_SUBJECT_DETAILS);
    can('manage', ORDERS_ACCESS_SUBJECT);
    can('manage', DASHBOARD_ACCESS_SUBJECT);
    cannot('read', PRODUCTS_QUANTITY);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    cannot('read', LOGS_ACCESS_SUBJECT);
    cannot('manage', LOGS_ACCESS_SUBJECT);
    cannot('read', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('manage', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('read', INVOICE_TAX_LOGS);
    cannot('manage', INVOICE_TAX_LOGS);
    cannot('manage', DELETE_SERVICE_CONTRACT);
  }
  if (roles.includes(ENGINEER_USER_ROLE)) {
    can('read', PRODUCTS_QUANTITY);
    can('manage', ORDERS_ACCESS_SUBJECT);
    can('manage', ORDERS_ACCESS_SUBJECT_DETAILS);
    cannot(['create', 'remove'], ORDERS_ACCESS_SUBJECT);
    cannot('read', PRICING_MANAGEMENT_ACCESS_SUBJECT);
    can('manage', DASHBOARD_ACCESS_SUBJECT);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    cannot('read', LOGS_ACCESS_SUBJECT);
    cannot('manage', LOGS_ACCESS_SUBJECT);
    cannot('read', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('manage', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('read', INVOICE_TAX_LOGS);
    cannot('manage', INVOICE_TAX_LOGS);
    cannot('manage', DELETE_SERVICE_CONTRACT);
  }
  if (roles.includes(CRM_USER_ROLE)) {
    can('manage', CRM_ACCESS_SUBJECT);
    can('manage', DASHBOARD_ACCESS_SUBJECT);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    cannot('read', LOGS_ACCESS_SUBJECT);
    cannot('manage', LOGS_ACCESS_SUBJECT);
    cannot('read', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('manage', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('read', INVOICE_TAX_LOGS);
    cannot('manage', INVOICE_TAX_LOGS);
    cannot('manage', DELETE_SERVICE_CONTRACT);
  }
  if (roles.includes(CUSTOMER_PORTAL)) {
    can('manage', DASHBOARD_ACCESS_SUBJECT);
    can('manage', PAYMENT_PORTAL);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    cannot('read', LOGS_ACCESS_SUBJECT);
    cannot('manage', LOGS_ACCESS_SUBJECT);
    cannot('read', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('manage', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('read', INVOICE_TAX_LOGS);
    cannot('manage', INVOICE_TAX_LOGS);
  }
  if (roles.includes(PROVISIONING)) {
    can('manage', DASHBOARD_ACCESS_SUBJECT);
    can('manage', REPORTS);
    can('manage', CRM_ACCESS_SUBJECT);
    cannot('manage', CRM_ENABLE_LOGIN_FOR_CONTACT);
    cannot('manage', CRM_CHANGE_USER_ROLES);
    cannot('manage', CRM_REMOVE_CONTACT_FROM_ORGANIZATION);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_WEBSITE);
    cannot('manage', CRM_ORG_CREDIT_CHECK_PASSED);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_FEDERAL_TAX_ID);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_BILLING_PLAN);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_PAYMENT_TERMS);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_TAX_EXEMPT_TYPE);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_TAX_EXEMPT_LEVEL);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_REQUESTED_W9);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_RECEIVED_W9);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_PARENT_ORGANIZATION);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_DEFAULT_ROLE);
    cannot('manage', CRM_ORG_PAYMENT_TERMS_ORGANIZATION_IS);
    cannot('manage', CRM_ORG_DESCRIPTION);
    cannot('manage', CRM_ORG_ATTACHMENTS_DELETE);
    cannot('manage', CRM_ORG_RELATIONSHIPS_DELETE);
    can('read', ORDERS_ACCESS_SUBJECT);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    can('read', ORDERS_ACCESS_SUBJECT_DETAILS);
    cannot('read', LOGS_ACCESS_SUBJECT);
    cannot('manage', LOGS_ACCESS_SUBJECT);
    cannot('read', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('manage', EMAIL_LOG_ACCESS_SUBJECT);
    cannot('read', INVOICE_TAX_LOGS);
    cannot('manage', INVOICE_TAX_LOGS);
    cannot('manage', DELETE_SERVICE_CONTRACT);
  }
  if (roles.includes(ADMIN_USER_ROLE)) {
    can('manage', 'all');
    cannot('manage', ENABLE_LOGIN_CONFIGURATION);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    can('manage', HIDDEN_TICKETS_FILTER);
  }
  if (roles.includes(OPERATIONS_ROLE)) {
    can('manage', ENABLE_LOGIN_CONFIGURATION);
    can('delete', CONTACT);
    can('manage', LEDGER_NOTES);
    cannot('read', CONTRACT_RENEW_ACTIONS);
    can(['manage', 'read'], ADVANCED_SEARCH_ACCESS_SUBJECT);
    can('manage', DELETE_SERVICE_CONTRACT);
  }
  if (roles.includes(OPERATIONS_ROLE) && roles.includes(ADMIN_USER_ROLE)) {
    can('read', CONTRACT_RENEW_ACTIONS);
  }

  return build();
};
