/**
 * @module store/config - All configs of app
 *
 * @version 1.0.0
 * @copyright viax.io 2019
 */

import { getPollInterval } from '@/utils';

export const DEFAULT_HISTORY_LENGTH = 5;

export const PRODUCTS_SEARCH_LIMIT = 50;
export const TICKETS_SEARCH_LIMIT = 50;
export const TASKS_SEARCH_LIMIT = 50;
export const ORGANIZATIONS_SEARCH_LIMIT = 150;
export const ORDERS_SEARCH_LIMIT = 150;
export const CONTACTS_SEARCH_LIMIT = 10;
export const BILLING_SEARCH_LIMIT = 20;
export const EMAIL_LOGS_SEARCH_LIMIT = 50;
export const CREATE_ORGANIZATION_RELATIONSHIP_SEARCH_LIMIT = 40;
export const CREATE_CONTACT_SEARCH_LIMIT = 40;
export const EQUIPMENT_RECORDS_SEARCH_LIMIT = 50;
export const BATCHES_SEARCH_LIMIT = 25;
export const PROJECTS_SEARCH_LIMIT = 50;
export const ADV_SEARCH_MCR_SEARCH_LIMIT = 20;
export const COLLECTIONS_SEARCH_LIMIT = 100;

// Fields for which organizations are searched
export const ORGANIZATIONS_SEARCH_FIELDS = [
  'name',
  'address.line1',
  'address.city',
  'address.state',
  'address.zipCode',
  'accountNumber',
  'phoneNumber',
];

// Fields for which workflows are searched
export const WORKFLOWS_SEARCH_FIELDS = [
  'name',
  'description',
];

// Fields for which roles are searched
export const ROLES_SEARCH_FIELDS = [
  'name',
  'description',
];

// Fields for which organizations are searched on Invoice Create and Update
export const INVOICE_ORGANIZATIONS_SEARCH_FIELDS = [
  'name',
  'code',
  'address.line1',
  'address.city',
  'address.state',
  'address.zipCode',
  'accountNumber',
  'phoneNumber',
];

// Fields for which tickets are searched
export const TICKET_SEARCH_FIELDS = [
  'title',
  'number',
  'description',
  'vendorTicketNumber',
  'contactOrganization.name',
  'contactOrganization.accountNumber',
];
// Fields for which tickets are searched
export const V_TICKET_SEARCH_FIELDS = [
  'title',
  'description',
  'displayName',
  'assignedUsers.name',
  'number',
  'tasks.title',
];

// Fields for which tickets are searched
export const V_TASK_SEARCH_FIELDS = [
  'title',
  'description',
  'assignedUsers.name',
  'number',
];

// Fields for which orders are searched
export const ORDERS_SEARCH_FIELDS = [
  'partners.address.line1',
  'partners.address.line2',
  'partners.address.city',
  'partners.address.state',
  'partners.address.zipCode',
  'partners.name',
  'partners.accountNumber',
  'internalOrderNumber',
  'otherPartyOrderNumber',
  'scIndexData.apxCircuitIds',
  'scIndexData.providerCircuitIds',
  'scIndexData.providerLocationZCircuitIds',
  'scIndexData.serviceLocationZDMARCs',
  'scIndexData.wanIpAddressNetworks',
  'scIndexData.wanIpAddressGateways',
  'scIndexData.wanIpAddressUseables',
  'scIndexData.lanIpAddressNetworks',
  'scIndexData.lanIpAddressGateways',
  'scIndexData.lanIpAddressUseables',
  'scIndexData.serviceLocationDMARCs',
];

// Fields for which invoices are searched
export const INVOICES_SEARCH_FIELDS = [
  'partners.address.line1',
  'partners.address.line2',
  'partners.address.city',
  'partners.address.state',
  'partners.address.country',
  'partners.address.zipCode',
  'partners.name',
  'partners.accountNumber',
  'partners.code',
  'number',
];
// Fields for which ledgers are searched
export const LEFGERS_SEARCH_FIELDS = [
  'customerLedger.customer.name',
  'customerLedger.customer.code',
  'customerLedger.customer.accountNumber',
  'customerLedger.customer.address.line1',
  'customerLedger.customer.address.line2',
  'customerLedger.customer.address.city',
  'customerLedger.customer.address.state',
  'customerLedger.customer.address.country',
  'customerLedger.customer.address.zipCode',
];

// Fields for which users and contacts are searched
export const USERS_AND_CONTACTS_SEARCH_FIELDS = [
  'name',
  'email',
  'phoneDetails.phoneNumber',
  'organizations.for.name',
];

// Fields for which users and contacts are searched without associated organizations
export const USERS_SEARCH_FIELDS_WITHOUT_ASSOCIATED_ORGANIZATIONS = [
  'name',
  'email',
  'phoneDetails.phoneNumber',
];

// Fields for which users are searched
export const TICKET_USERS_SEARCH_FIELDS = [
  'name',
  'phoneDetails.phoneNumber',
  'organizations.name',
];

// Fields for which contacts for organization.contacts are searched
export const USERS_AND_CONTACTS_FOR_ORGANIZATION_SEARCH_FIELDS = [
  'name',
  'phoneDetails.phoneNumber',
];

// Fields for which products are searched
export const PRODUCTS_SEARCH_FIELDS = [
  'name',
  'family',
  'sku',
];

// Fields for which email logs are searched
export const EMAIL_LOGS_SEARCH_FIELDS = [
  'subject',
  'recipients.email',
];

// Fields for which email logs are searched
export const PAYMENT_LOGS_SEARCH_FIELDS = [
  'organizationName',
  'organizationAccountNumber',
];

// Fields for which organizations are searched on interactive configuration
export const CONFIGURATION_ORGANIZATIONS_SEARCH_FIELDS = [
  'name',
  'address.line1',
  'address.city',
  'address.state',
  'address.zipCode',
  'accountNumber',
  'phoneNumber',
];

// Fields for which equipment records are searched
export const EQUIPMENT_RECORDS_SEARCH_FIELDS = [
  'serialNumber',
];

// Fields for which groups are searched
export const GROUPS_SEARCH_FIELDS = [
  'name',
];

// Fields for which groups are searched
export const USER_GROUPS_SEARCH_FIELDS = [
  'name',
];

// Fields for which attributes are searched
export const ATTRIBUTES_SEARCH_FIELDS = [
  'name',
];

// Fields for which product configurations are searched
export const PRODUCT_CONFIGURATIONS_SEARCH_FIELDS = [
  'name',
];

// Fields for which pricing models are searched
export const PRICING_MODELS_SEARCH_FIELDS = [
  'name',
];

// Fields for which invoice batches are searched
export const BATCHES_SEARCH_FIELDS = [
  'items.soldTo.name',
];

// Fields for which invoice batch items are searched
export const BATCH_ITEMS_SEARCH_FIELDS = [
  'soldTo.name',
  'invoice.number',
];

// Fields for which projects are searched
export const PROJECTS_SEARCH_FIELDS = [
  'name',
];

export const COLLECTIONS_SEARCH_FIELDS = [
  'customer.name',
  'customer.accountNumber',
  'customer.address.line1',
  'customer.address.city',
  'customer.address.state',
  'customer.address.zipCode',
];

export const SEARCH_DEBOUNCE_TIMEOUT = 400;

// Number of entities to display in car on
export const NUMBER_OF_ENTITIES_TO_DISPLAY_IN_CARD = 5;

// POLL_INTERVAL
export const POLL_INTERVAL = getPollInterval();
